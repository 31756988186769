<template>
    <div>
        <el-upload
            ref="upload"
            class="geology-single-file"
            :headers="dataHeader"
            :action="uploadurl"
            :auto-upload="autoUpload"
            :show-file-list="showFileList"
            :file-list="fileList"
            :limit="limit"
            :list-type="listType"
            :on-change="onChange"
            :on-exceed="onExceed"
            :on-success="onSuccess"
            :on-error="onError"
            :on-remove="onRemove"
        >
            <el-button size="small" type="primary">{{ text }}</el-button>
        </el-upload>
    </div>
</template>
<style lang="scss">
.geology-single-file {
    .el-upload-list__item-name {
        color: #fff;
        &:hover {
            color: #555;
            [class^="el-icon"] {
                color: #555;
            }
        }
        [class^="el-icon"] {
            color: #fff;
        }
    }
    .el-upload-list__item {
        &:hover {
            a {
                color: #555;
            }
            [class^="el-icon"] {
                color: #555;
            }
        }
    }
}
</style>
<script>
export default {
    data() {
        return {
            change: false,
            dataHeader: {
                auth: this.$store.getters.token,
            },
        };
    },
    computed: {
        uploadurl: function () {
            return (
                this.$extconfig.getConfig("apibase", "/") +
                "/api/Upload/ExportFile"
            );
        },
    },
    props: {
        text: {
            type: String,
            default: "选择文件",
        },
        autoUpload: {
            type: Boolean,
            default: true,
        },
        fileList: {
            type: Array,
            default: function () {
                return [];
            },
        },
        showFileList: {
            type: Boolean,
            default: false,
        },
        listType: {
            type: String,
            default: "picture",
        },
        limit: {
            type: Number,
            default: 1,
        },
    },
    methods: {
        onSuccess(response) {
            if (response.code == 0) {
                this.$emit("success", response.data);
            } else {
                this.$message.error("上传文件失败");
            }
        },
        onChange(file, fileList) {
            this.change = true;
            this.$emit("change", file, fileList);
        },
        onRemove(file, fileList) {
            this.$emit("remove", file, fileList);
        },
        onExceed(file, fileList) {
            this.$message.error("请先移除文件列表文件");
        },
        onError() {
            this.$message.error("上传文件失败");
        },
        submit() {
            if (this.change) {
                this.$refs.upload.submit();
            } else {
                if (this.fileList.length > 0) {
                    this.$emit("success", {
                        path: this.fileList[0].url,
                    });
                }
            }
        },
    },
};
</script>